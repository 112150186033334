<template>
  <div class="container">
    <!-- <div class="header">
      <span>
        <h3>Criar um novo stakeholder</h3>
        <p style="text-align:left">Preencha com atenção</p>
      </span>
      <div class="grow"/>
      <button type="button" class="btn-voltar" @click="$router.go(-1)">voltar</button>
    </div> -->
    <form class="formulario" autocomplete='off'>
      <v-row>
        <v-col
          cols="12"
          sm="12"
        >
            <!-- <Dropdown
                :multiple="false"
                :options="usuarios"
                id="usuario-select"
                v-model="usuario"
                data-cy="usuario"
                :border="'1px solid rgb(62, 62, 62)'"
                :borderradius="'0.3rem'"
                :name="'usuario'"
                :placeholder="'usuario'"
                :error="erros.usuario"
                :onmousedown="(e) => selecionarcampo(e)"
              /> -->
              <v-text-field
                @mousedown="(e) => selecionarcampo(e)"
                
                v-model="nome"
                name="nome"
                label="Nome"
                outlined
                :error-messages="erros.nome"
              ></v-text-field>
        </v-col>

        <v-col
          cols="12"
          sm="12"
        >
              <v-text-field
                
                v-model="telefone"
                name="telefone"
                label="Telefone"
                outlined
                :error-messages="erros.telefone"
                @mousedown="(e) => selecionarcampo(e)"
              ></v-text-field>
        </v-col>

        <v-col
          cols="12"
          sm="12"
        >
              <v-text-field
                
                v-model="email"
                name="email"
                label="Email"
                type="email"
                outlined
                :error-messages="erros.email"
                @mousedown="(e) => selecionarcampo(e)"

              ></v-text-field>
        </v-col>
        <v-col
          cols="12"
          sm="12"
        >

              <v-select
                @mousedown="(e) => selecionarcampo(e, 'stakeholdertipo')"
                :items="stakeholdertipos"
                label="Tipo Stakeholder"
                name="stakeholdertipo"
                v-model="stakeholdertipo"
                :error-messages="erros.stakeholdertipo"
                outlined
        ></v-select>
        </v-col>
      </v-row>  
      <v-row>
        <v-col
          cols="12"
          sm="12"
        >
          
              <v-select
                @mousedown="(e) => selecionarcampo(e, 'ambiente')"
                :items="ambientes"
                label="Ambiente"
                name="ambiente"
                v-model="ambiente"
                :error-messages="erros.ambiente"
                outlined
        ></v-select>
        </v-col>
      </v-row>   
      <v-row>
        <v-col
          cols="12"
          sm="12"
        >
          

              <!-- <v-select v-if="clienteSelecionado"
                @mousedown="(e) => selecionarcampo(e, 'ambiente')"
                :items=[clienteSelecionado]
                name="clientes"
                v-model="clienteSelecionado"
          
                outlined></v-select> -->
 
              <v-select 
                :items="this.clientes"
                name="clientes"
                v-model="clienteSelecionado"
                outlined></v-select>

        </v-col>
      </v-row> 
      <v-row>
        <v-col
          cols="12"
          sm="6"
        >
          <v-switch
            v-model="status"
            name="status"
            inset
            :label="`Usuário ${status ? 'Principal' : 'Normal'}`"
          ></v-switch>
        </v-col>
      </v-row>  
      <v-row>
        <v-col
          cols="12"
          sm="12"
        >
          <v-btn
            elevation="0"
            :ripple="false"
            class="btn"
            data-cy="btn-envio"
            color="#1377F2"
            type="submit"
            :disabled="reqloading"
            :loading="reqloading"
            @click="submeterform">
            criar stakeholder
          </v-btn>
        </v-col>
      </v-row>    
    </form>
  </div>
  
</template>

<script>
import {alertar} from '@/services/notificacao.service.js'
import StakeHServico from '@/services/stakeholder.service.js'
import UsuarioServico from '@/services/usuario.service.js'
import ClienteServico from '@/services/cliente.service.js'
export default {
  props: ['retornar', 'fechar', "clienteSelecionado"],
  data(){
    return {
      telefone:null,
      usuarios: [],
      usuario: null,
      clientes: [],
      clientesdb: [],
      cliente: null,
      status: null,
      reqloading: false,
      nome:null,
      email:null,
      stakeholdertipos: [],
      stakeholdertiposdb: [],
      stakeholdertipo: null,
      ambientes: ['Economy','Cliente','Todos'],
      ambientesdb: [{nome: 'Economy', id: 1}, {nome: 'Cliente', id: 2}, {nome: 'Todos', id: 3}],
      ambiente: null,
      erros: {
        nome: '',
        email: '',
        stakeholdertipo: '',
        ambiente: '',
      }
    }
  },
  methods: {
    async submeterform(e){
      e.preventDefault()
      if(this.validacao()){
        this.reqloading = true
        this.cliente = this.clienteSelecionado
        const dadosusuario = {
          principal: this.status,
          nome:this.nome,
          telefone: this.telefone,
          email:this.email,
          idimplantacao:null,
          idambiente: this.ambientesdb.filter(el => el.nome === this.ambiente)[0].id,
          idtipo: this.stakeholdertiposdb.filter(el => el.nome === this.stakeholdertipo)[0].id,
          idcliente:this.clientesdb.filter(el => el.nome === this.cliente)[0].id
        }
        
        await StakeHServico.criar(dadosusuario).then(res => {
          if(res.status === 200){
            if(this.retornar && this.retornar === true){
              this.$emit('input', res.data)
            }
            if(this.fechar){
              this.fechar()
            }
            this.reqloading = false
            alertar('success', '=)', 'StakeHolder criado com sucesso')
          }
        }).catch(e => {
          this.reqloading = false
          console.log('error criarcao stakeholder', e)
          alertar('warning', '=/', 'Falha na criação do StakeHolder')
        })
        this.reqloading = false
      }
    },
    async requisitarClientes(){
      this.clientes = await ClienteServico.buscar().then(res => {
        this.clientesdb = res.data
        // return res.data.map(item => ({nome: item.nome, value: item.id}))
        return res.data.map(item => item.nome)
      })
    },
    validacao(){
      let objretorno
      let validado = new Array()
      if(!this.nome || this.nome.trim() === ''){
        validado.push(false)
        this.erros.nome = 'Esse campo é obrigatório'
      }

      if(!this.telefone || this.telefone.trim() === ''){
        validado.push(false)
        this.erros.telefone = 'Esse campo é obrigatório'
      }

      if(!this.email || this.email.trim() === ''){
        validado.push(false)
        this.erros.email = 'Esse campo é obrigatório'
      }
      
      if(!this.stakeholdertipo || this.stakeholdertipo.trim() === ''){
        validado.push(false)
        this.erros.stakeholdertipo = 'Esse campo é obrigatório'
      }
      if(!this.ambiente || this.ambiente.trim() === ''){
        validado.push(false)
        this.erros.ambiente = 'Esse campo é obrigatório'
      }
      validado.includes(false) ? objretorno = false : objretorno = true
      return objretorno
    },
    selecionarcampo(e, type){
      if(this.erros[e.target.getAttribute('name')]){
        this.erros[e.target.getAttribute('name')] = null
      }
      if(type){
        this.erros[type] = null
      }
    },
    async requisitarUsuarios(){
      this.usuarios = await UsuarioServico.buscar().then(res => res.data.map(user => ({nome: user.nome, value: user.id})))
    },
    async requisitarTiposStakeholder(){
      this.stakeholdertipos = await StakeHServico.buscarTipo().then(res =>{
        this.stakeholdertiposdb = res.data
        return res.data.map(tipo => tipo.nome)})
    }
  },
  async mounted(){
    await this.requisitarUsuarios()
    await this.requisitarTiposStakeholder()
    await this.requisitarClientes()
  }
}
</script>

<style lang="scss" scoped>
.container{
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: calc(100% - 64px);
  @media screen and (max-width:768px) {
    height: calc(100% - 56px);
  }
}
.header{
  display: flex;
  padding: 1em;
    margin: 0 auto;
  width: 60vw;
  max-width: 100%;

  >h3{
    letter-spacing: 0.02em;
  }
  margin: 1em auto;
   background: rgba(215,211,222,0.5);
  height: fit-content;
  border-radius: 10px;
  >span{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
}

.btn-voltar{
    padding: .75em 1em;
    line-height: 1.5;
    color: white;
    font-weight: bold;
    letter-spacing: 0.02em;
    background: #1377f2;
    border-radius: 10px;
    text-transform: capitalize;
  }

.btn{
  background-color: #1377F2;
  width: 100%;
  height: 3em !important;
  color: whitesmoke;
  text-transform: uppercase;
  letter-spacing: 0.02em;
  padding: 1em;
  border-radius: 10px;
  font-weight: bold;
}
.formulario{
  margin: 0 auto;
  width: 900px;
  max-width: 80vw;
  background-color: #f9f9f2;
  box-shadow:  4px 4px 10px #d9d9d9;
  padding: 5em 1em 3em 1em;
  border-radius: 30px;
}

.theme--light.v-messages {
    color: red !important;
}

//css indesejados
.v-input, .v-text-field{
  outline: none !important;
}
.v-text-field__details{
  display: none !important;
}

.col-sm-6, .col-12{
  padding: 0 12px !important;
}

.v-btn:not(.v-btn--round).v-size--default {
    min-width: unset !important;
    padding: unset !important;
  }
</style>