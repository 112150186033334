<template>
  <v-container>
      <v-row style="margin-top:10px;">
        <v-col cols='12' md='8'>
              <v-card class="" elevation="" max-width="" style="padding-top:30px;" >
        
                <v-card-text>

                    <v-row>
                      <v-col cols="12" md="6">
                              <v-select :error-messages="erros.sistema"  @mousedown="(e) => selecionarcampo(e, 'sistema')" v-model="sistema" label="Sistema" item-text="nome" item-value="id" :items="sistemas" outlined return-object hide-details dense >
                              </v-select>
                      </v-col>
                      <v-col cols="12" md="6">
                              <v-select @mousedown="(e) => selecionarcampo(e, 'cliente')" :items="clientes" label="Cliente" name="cliente" v-model="cliente" @change="requisitarStakeholders()" :error-messages="erros.cliente" outlined dense >
                              </v-select>
                      </v-col>
                    </v-row>

                    <v-row>
                      <v-col cols="12" md="12">
                              <v-select @mousedown="(e) => selecionarcampo(e, 'fluxo')" :items="fluxos" label="Fluxo" name="fluxo" v-model="fluxo" :error-messages="erros.fluxo" @change="requisitarEtapas" outlined dense >
                              </v-select>
                      </v-col>

                    </v-row>
                    
                    <v-row style="margin-top:30px;">
                      <v-col cols="12" md="12">
                        
                          <div>
                            <v-row>
                              <v-col cols="12" md="6"><h2>Envolvidos</h2></v-col>
                              <v-col cols="12" md="6" style="display:flex;justify-content:flex-end;">
                                  <v-btn small elevation="" color="info" @click="modalstakeholder=!modalstakeholder" >Adicionar</v-btn>
                              </v-col>
                            </v-row>
                            
                          </div>

                          <v-data-table data-table-dense-header-height='100px' :headers="headerstakeholders" :items="stakeholders" :items-per-page="20" style="width:100%;margin-top:20px;" >

                                <template v-slot:[`item.nome`]="{ item }">
                                    <v-btn v-if="item.nome" small elevation="" color="white" >
                                        <span :style="'color:'+(item.ativo?'black':'red;text-decoration:line-through;')">{{item.nome}}</span>
                                    </v-btn>
                                </template>

                                <template v-slot:[`item.principal`]="{ item }">
                                    <v-btn v-if="item.principal" small elevation="" color="white" >
                                         <v-icon color="success">mdi-check</v-icon>
                                    </v-btn>
                                </template>

                                <template v-slot:[`item.idusuario`]="{ item }">
                                    <v-btn v-if="item.ativo" small elevation="" color="error" @click="setModalRemoverEnvolvido(item)" >
                                         <v-icon color="">mdi-delete</v-icon>
                                    </v-btn>
                                    <v-btn v-else small elevation="" color="success" @click="reativarStake(item)"  title="reativar">
                                         <v-icon color="">mdi-check</v-icon>
                                    </v-btn>
                                </template>

                                <template v-slot:[`item.email`]="{ item }">
                                    <div >
                                      <v-row :style="'margin-bottom:2px;'"><a :style="(item.ativo?'':'color:red;text-decoration:line-through;')" >{{item.email}}</a></v-row>
                                      <v-row :style="'margin-top:2px;'"><a :style="(item.ativo?'':'color:red;text-decoration:line-through;')" >{{item.fone}}</a></v-row>
                                    </div>
                                </template>

                          </v-data-table>

                      </v-col>

                    </v-row>

                    <v-row>
                      <v-col cols="12" md="6"></v-col>
                      <v-col cols="12" md="6">
                            <v-btn elevation="0" :ripple="false" class="btn-envio whitecolor mb" type="submit" color="#1377f2" @click="submeter">
                                  atualizar implantação
                            </v-btn>
                      </v-col>
                    </v-row>
                   


                </v-card-text>
                
              </v-card>
        </v-col>

        <v-col cols='12' md='4'>

              <v-card class="" elevation="" max-width="" >
        
                <v-card-text>
                  

                    <v-card  v-for="etapa, idx in etapasdefault" @click="() => selecionarEtapa(etapa)" :key="idx" class="" elevation="" max-width="" style="margin-bottom:10px;"  >

                        <v-card-text :style="'background-color:'+(etapa.id == implantacao.idetapaatual?'green':(etapa.ordemfluxo < implantacao.ordemetapaatual? '#dfdfdf':'white'))+';color:'+(etapa.id == implantacao.idetapaatual?'white;font-weight:bold;':'black')+';padding-left:20px;padding-right:30px;'" >
                            
                            <v-row style="height:40px;display:flex;align-items:center;">
                              
                              <v-col cols="12" md="8" v-if="etapa.ordemfluxo < implantacao.ordemetapaatual" style="text-decoration: line-through;">{{ etapa.nome}}</v-col>
                              <v-col cols="12" md="8" v-else >{{ etapa.nome}}</v-col>

                             
                              <v-col cols="12" md="4" > 
                                  <v-btn v-if="etapa.id == implantacao.idetapaatual" elevation="0"  :ripple="false" class="" style="width:100px;" color="" @click="$router.push(`/lista/implantacao/realizar/${implantacao.id}/${idclienteEs}`)" >
                                      <span style="font-size:10px;font-weight:bold;">Avançar</span>
                                  </v-btn>
                                  <a v-if="etapa.resposta.resposta && etapa.id != implantacao.idetapaatual" style="width:100px;text-decoration:underline;font-weight:bold;padding-right:0px;" @click="setModalResposta(etapa)" >
                                      <span style="font-size:14px;font-weight:bold;">Ver Dados</span>
                                  </a>
                                  
                              </v-col>
                            </v-row>
                        </v-card-text>

                      </v-card>

                </v-card-text>
                
              </v-card>

        </v-col>
      </v-row>


  
      <v-dialog v-model="exibirModalResposta" width="80vw" max-width="80vw">
          <div style="background-color:white;width:100%;display:flex;flex-direction:column;">
              <div>
                 <v-container class="pa-8">
                    
                    <h1>Dados</h1>
                    <v-row v-for="resposta,idx in objetoResposta" :key="idx" style="margin-bottom:10px;padding-bottom:5px;border-bottom:1px solid #9f9696;margin-top:10px;margin-left:10px;margin-right:10px;">
              

                      <v-col cols="12" md="3">
                            {{resposta.pergunta}}
                      </v-col>

                      <v-col cols="12" md="9">

                        <div v-if="resposta.resposta && resposta.resposta.substring(0,1) != '{'  ">
                          {{resposta.resposta}} 
                        </div>

                        <div v-if="resposta.resposta && resposta.resposta.substring(0,1) == '{'  " style="padding:10px;">

                          <v-row v-if="JSON.parse(resposta.resposta).resposta != ''">
                            <v-col cols="12" md="12">
                              {{JSON.parse(resposta.resposta).resposta}}
                            </v-col>
                          </v-row>

                          <v-row v-if="JSON.parse(resposta.resposta).perguntasextras && JSON.parse(resposta.resposta).perguntasextras.length> 0" style="padding:20px;" >
                            <v-col cols="12" md="12" >

                              <v-row v-for="perg,idx2 in JSON.parse(resposta.resposta).perguntasextras" :key="idx2" style="margin-bottom:10px;padding-bottom:5px;border-bottom:1px solid #9f9696;">
                                  <v-col cols="12" md="7">{{perg.pergunta}}</v-col>
                                  <v-col cols="12" md="5">{{perg.respostavalor}}</v-col>
                              </v-row>
                            </v-col>
                          </v-row>
                          
                        </div>
                      </v-col>

                    </v-row>

                  </v-container>
              </div>
             
          </div>
    </v-dialog>


    <v-dialog v-model="exibirModalRemoveStakeHolder" width="400px" max-width="400px">
          <div style="background-color:white;width:100%;display:flex;flex-direction:column;">
              <div>
                 <v-container class="pa-8">
                    
                    <h4>Confirmar Remoção de StakeHolder {{stakeholderSelecionado.nome}}?</h4>

                    <div style="display:flex;flex-direction:row;justify-content:space-between;margin-top:30px;">
                      <div>
                            <v-btn small elevation="" color="error" @click="exibirModalRemoveStakeHolder=false">Cancelar</v-btn>
                      </div>
                      <div>
                        <v-btn small elevation="" color="success" @click="removerStake(stakeholderSelecionado)">Confirmar</v-btn>
                      </div>
                    </div>

                  </v-container>
              </div>
             
          </div>
    </v-dialog>

    
    <div class="modal-stakeholder-wrapper" v-if="modalstakeholder">
      <button type="button" @click="modalstakeholder=!modalstakeholder">
        <v-icon color="#F23E2C">mdi-close-circle-outline</v-icon> fechar
      </button>
      <CriarStakeholder v-model="novostakeholder"  :retornar="true" :fechar="()=>modalstakeholder=!modalstakeholder"/>
    </div>
  </v-container>
  
</template>

    
<script>
import {alertar} from '@/services/notificacao.service.js'
import FluxoServico from '@/services/fluxo.service.js'
import ImplantacaoServico from '@/services/implantacao.service.js'
import ClienteServico from '@/services/cliente.service.js'
import EtapaServico from '@/services/etapa.service.js'
import GadgetServico from '@/services/gadget.service.js'
import StakeHServico from '@/services/stakeholder.service.js'
import SistemaServico from '@/services/sistema.service.js'
// import MovimentoServico from '@/services/movimento.service.js'
import CriarStakeholder from '@/components/Stakeholder/CriarStakeholder.vue'
export default {
  components: {CriarStakeholder},

  data(){
    return{
      sistema: null,
      sistemas: [],
      fluxo: null,
      fluxos: [],
      clientes: [],
      cliente: null,
      emails: [], 
      nomes: [], 
      categorias: [], 
      categoria: null, 
      stakeholder: null,
      headerstakeholders:[
        { text: "ID", value: "id", width:'15%'},
        { text: "Principal", value: "principal", width:'20%'},
        { text: "Nome", value: "nome", width:'20%'},
        { text: "Contato", value: "email", width:'35%'},
        { text: "Remover", value: "idusuario", width:'10%'},
      ],
      stakeholders: [],
      implantacao: {},
      perguntasselecionadas: [],
      veretapas: false,
      erros: {
        sistema: '',
        cliente: '',
        emails: '', 
        nomes: '', 
        categoria: '', 
        stakeholder: '',
        fluxo: '',
      },
      etapas: [],
      etapasdefault: [],
      etapa: null,
      tipogadget: [],
      gadgetselecionado: {},
      sistemasdb: [],
      clientesdb: [],
      stakeholdersdb: [],
      fluxosdb: [],
      modalstakeholder: false,
      novostakeholder: {},
      implantacaofull: null,
      exibirModalResposta:false,
      objetoResposta:{},
      stakeholderSelecionado:{},
      exibirModalRemoveStakeHolder:false,
      idclienteEs: null,
    }
  },
  methods:{


    setModalResposta(etapa){

      this.objetoResposta = etapa.resposta.resposta
      this.exibirModalResposta = !this.exibirModalResposta

    },
    setModalRemoverEnvolvido(stake){

      this.stakeholderSelecionado = stake
      this.exibirModalRemoveStakeHolder = !this.exibirModalRemoveStakeHolder

    },
    async removerStake(stake){
        this.exibirModalRemoveStakeHolder=false
        await StakeHServico.removerStakeHolder(stake)
                  .then(() => { console.log('sucesso vincular stakeholder') })
                  .catch((e) => { console.log('erro em vincular stakeholder', e) })
        this.requisitarStakeholders()
    },
    async reativarStake(stake){

        await StakeHServico.reativarStake(stake)
                  .then(() => { console.log('sucesso vincular stakeholder') })
                  .catch((e) => { console.log('erro em vincular stakeholder', e) })
        this.requisitarStakeholders()
    },
    async requisitarSistemas(){

      const sistemas = await SistemaServico.buscar({semimagem:1}).then(res => res.data)
      this.sistemasdb = sistemas
      this.sistemas = sistemas.map(item => item.nome)
    },
    async requisitarEtapas(){
      const idfluxo = this.fluxosdb.filter(el => el.nome === this.fluxo)[0].id
      this.etapas = await EtapaServico.buscarPorFluxoImplantacao({idfluxo:idfluxo,idimplantacao:this.implantacao.id}).then(res => {
        console.log(res.data)
        this.etapasdefault = res.data
        console.log('this.etapasdefault  << ',this.etapasdefault )
        return res.data.map(item => ({nome: item.nome, value: item.id, idgadget: item.idgadget}))
      })
    console.log(this.etapas)
      this.veretapas = false
   
    },
    async requisitarCategorias(){
      this.categorias = ['Categoria 1', 'Categoria 2', 'Categoria 3']
    },
    async requisitarClientes(){
      const clientes = await ClienteServico.buscar().then(res => res.data)
      this.clientes = clientes.map(item =>item.nome)
      this.clientesdb = clientes
     
    },
    async requisitarStakeholders(){

      let idcliente = this.clientesdb.filter(el => el.nome === this.cliente)[0].id
      this.idclienteEs = idcliente
      const stakeholders = await StakeHServico.buscar({idcliente:idcliente}).then(res => res.data)
      this.stakeholders = stakeholders
    
      this.stakeholdersdb = stakeholders
    },
    async requisitarFluxos(){
      const fluxos = await FluxoServico.buscar().then(res => res.data)
      this.fluxos = fluxos.map(fluxo => fluxo.nome)
      this.fluxosdb = fluxos

    },
    async requisitarGadget(id){
      return await GadgetServico.buscar({id: id}).then(res => res.data[0])
    },
    async requisitarTipoGadget(){
      this.tipogadget = await GadgetServico.tipo().then(res => res.data)
    },
    selecionarcampo(e, type){
      if(this.erros[e.target.getAttribute('name')]){
        this.erros[e.target.getAttribute('name')] = null
      }
      if(type){
        this.erros[type] = null
      }
    },
    validacao(){
      let validado = true
      if(this.cliente == null){
        validado = false
        console.log('cliente')
        this.erros.cliente = 'O campo nome do cliente é obrigatório'
      }

      if(this.fluxo == null){
        validado = false
        console.log('fluxo')
        this.erros.fluxo = 'O campo fluxo é obrigatório.'
      }
      if(this.sistema == null){
        validado = false
        console.log('sistema')
        this.erros.sistema = 'O campo sistema é obrigatório.'
      }

      if(this.stakeholder == null){
        validado = false
        console.log('stakeholder')
        this.erros.stakeholder = 'O campo stakeholder é obrigatório.'
      }
      return validado
    },
    async submeter(e){
      e.preventDefault()
      if(this.validacao()){
        const dadosimplantacao = {
          id: this.implantacao.id,
          idsistema: this.sistemasdb.filter(el => el.nome === this.sistema)[0].id,
          idcliente: this.clientesdb.filter(el => el.nome === this.cliente)[0].id,
          urlsite: "xpto.com",
          urlapi: "xapi.com",
          idfluxo: this.fluxosdb.filter(el => el.nome === this.fluxo)[0].id,
          datainicio: null,
          dataconclusaoestimada: null,
          dataconclusao: null,
          concluida: false
        }
        await ImplantacaoServico.criar(dadosimplantacao)
          .then(res => {
            if(res.status === 200){
              this.stakeholder.map(async stake => {
                const idstake = this.stakeholdersdb.filter(el => el.nome == stake)[0].id
                const idusuario = this.stakeholdersdb.filter(el => el.nome == stake)[0].idusuario
                await StakeHServico.criar({
                  id: parseInt(idstake), 
                  idusuario: parseInt(idusuario), 
                  idimplantacao: this.implantacao.id
                  })
                  .then(() => {
                    console.log('sucesso vincular stakeholder')
                  })
                  .catch((e) => {
                    console.log('erro em vincular stakeholder', e)
                  })
              })
              alertar('success', '=)', 'Implantação atualizada com sucesso!')
            }
          })
          .catch(e => {
            alertar('warning', '=/', 'Falha na ataualização da implantação')
            console.log('error na atualização de implantação', e)
          })
      }
    },
    async requisitarImplantacao(id){
      this.implantacao = await ImplantacaoServico.buscar({id: parseInt(id)}).then(res => res.data[0])
      this.sistema = this.sistemasdb.filter(el => el.id === this.implantacao.idsistema)[0].nome
      this.fluxo = this.fluxosdb.filter(el => el.id === this.implantacao.idfluxo)[0].nome
      this.cliente = this.clientesdb.filter(el => el.id === this.implantacao.idcliente)[0].nome
      const implantacao = await ImplantacaoServico.buscarFull({id: parseInt(id)}).then(res => res.data)
      this.implantacaofull = implantacao
      // this.stakeholder = implantacao.stakeholders && implantacao.stakeholders.map(item => item.nome)
    },
    selecionarEtapa(etapa){
      this.etapa = null
      if(this.etapa === null){
        this.etapa = etapa
      }else{
        if(etapa.nome === this.etapa.nome){
          this.etapa = null
        }else{
          this.etapa = etapa
        }
      }
    },
    toLeft(){
      document.getElementById('slider').scrollLeft -= document.getElementById('slider').getBoundingClientRect().width/3
    },
    toRight(){
      document.getElementById('slider').scrollLeft += document.getElementById('slider').getBoundingClientRect().width/3
    }
  },
  watch: {
    // etapa: async function(){
    //   if(this.etapa !== null){
    //     console.log(this.etapasdefault)
    //     const idgadget = this.etapasdefault.filter(el => el.id === this.etapa.id)[0].idgadget
    //     console.log('id >>>>>>>>>>>>', idgadget)
    //     const gadget = await this.requisitarGadget(idgadget)
    //     this.gadgetselecionado = gadget
    //     if(this.gadgetselecionado.idtipo === 2){
    //       let perguntas = await GadgetServico.buscarPerguntas(this.gadgetselecionado.id).then(res =>res.data)
    //       const movimento = await MovimentoServico.buscar({
    //         idetapa: this.etapa.value,
    //         idimplantacao: this.implantacao.id
    //       }).then(res => res.data[0])
    //       if(movimento){
    //         console.log('movimento', movimento)
    //         let respostas = movimento.respostas
    //         if(respostas) {

    //           perguntas = perguntas.map(pergunta => {
    //             let resposta = respostas.filter(el => el.idpergunta === pergunta.id)[0].resposta
    //             if(pergunta.respostasfechadas){
    //               resposta = JSON.parse(resposta)
    //             }
    //             return {
    //               ...pergunta,
    //               resposta: resposta
    //             }
    
    //           })
    //         }
    //         this.perguntasselecionadas = perguntas
    //         const novoestado = this.perguntasselecionadas.sort((a, b) => {
    //           return a.id - b.id 
    //         });
    //         this.perguntasselecionadas  = novoestado
    //       }
    //     }
    //   }else{
    //     this.gadgetselecionado = {}
    //   }
    // },
    novostakeholder: async function(){
      await this.requisitarStakeholders()
    }
  },
  computed: {
    isObject: function(){
      return val => val instanceof Object
    }
  },
  async mounted(){
    const { id } = this.$route.params
    try{
          
    await this.requisitarSistemas()
    await this.requisitarCategorias()
    await this.requisitarFluxos()
    await this.requisitarClientes()
    await this.requisitarImplantacao(id)
    await this.requisitarEtapas()
    await this.requisitarTipoGadget()
    await this.requisitarStakeholders()
    }catch(e){
      console.log('error mounted', e)
    }
  }
}
</script>

<style lang="scss" scoped>


.header{
  display: flex;
  padding: 1em;

  >h3{
    letter-spacing: 0.02em;
  }
  margin: 1em auto;
   background: rgba(215,211,222,0.5);
  height: fit-content;
  border-radius: 10px;
  >span{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
}

.btn-voltar{
    padding: .75em 1em;
    line-height: 1.5;
    color: white;
    font-weight: bold;
    letter-spacing: 0.02em;
    background: #1377f2;
    border-radius: 10px;
    text-transform: capitalize;
  }

.form{
  margin: 1em auto;
  width: 1024px;
  max-width: 80vw;
  box-shadow:  4px 4px 20px #d9d9d9,;
  padding: 5em 1em 3em 1em;
  border-radius: 30px;
  background-color: #f9f9f2;
}

.theme--light.v-messages {
    color: red !important;
}

//css indesejados
.v-input, .v-text-field{
  outline: none !important;
}
.v-text-field__details{
  display: none !important;
}

.col-sm-6, .col-12{
  padding: 0 12px !important;
}

.v-btn:not(.v-btn--round).v-size--default {
    min-width: unset !important;
    padding: unset !important;
  }
.grow{
  flex: 1;
}
.contrato{
  text-align: start;
  max-height: 95%;
  overflow-y: scroll;
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
  &::-webkit-scrollbar{
    display: none;
  }
}
.formulario{
  overflow-y: scroll;
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
  max-height: 95%;
  &::-webkit-scrollbar{
    display: none;
  }
  .pergunta-wrapper{
    padding: 1em;
    margin: 1em 0;
    border-radius: 15px;
    background: #ffffff;
    box-shadow:  2px 5px 20px #d9d9d9;
    height: fit-content;
    width: 100%;
    text-align: start;
    display: flex;
    flex-direction: column;
    align-items: flex-start;




    .pergunta{
      font-weight: bold;
      color: #666;
      font-size: 15px;
    }
    .resposta{
      font-weight: 400;
      color: #000; 
      font-size: 16px;
    }
    .pergunta-extra{
      margin: .4em 0;
      border-bottom: 2px solid #eee;
    }

  }
}
.container{
  // height: calc(100% - 56px);
  // @media screen and (min-width:768px) {
  //   height: calc(100% - 64px);
  // }
  padding-bottom: 1em;
  section:nth-child(4){
    margin: 2em auto;
  }
  section:nth-child(2){
    padding-top: 1em;
  }
}

section{
  margin: 1em auto;
  width: 1024px;
  max-width: 80vw;
  box-shadow:  4px 4px 20px #d9d9d9,;
  border-radius: 30px;
  background-color: #f9f9f2
}

.slider-controls{
  position: sticky;
  left: 0;
  display: flex;
  background: #f9f9f2;
  z-index: 100;
  button:nth-child(1){
    margin-right: .5em;
  }
}
.section-nav{
  display: flex;
  align-items: center;
  justify-content: center;
  ul{
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    background: #f9f9f2;
    padding: 1em;
    width: 100%;
    overflow-x: scroll;
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
    &::-webkit-scrollbar {
      display: none;
    }
    scroll-behavior: smooth;
    li{
      margin: 0 .5em;
      padding: 1em 1.5em;
      border-radius: 30px;
      background: #f9f9f2;
      box-shadow:  4px 4px 10px #d9d9d9,
             4px 4px 10px #ffffff;
      font-size: 12px;
      transition: .1s all;
      text-transform: capitalize;
      flex-shrink: 0;
      &:hover{
        transition: .1s all;
        cursor: pointer;
        transform: scale(1.1);
      }
    }
  }

  >button{
    padding: 0 1em;
  }
}

.section-data{
  &.etapas{
    height: 20em;
    background: #f9f9f2;
    border-radius: 30px;
    .etapa{
      width: 100%;
      height: 100%;
      &.empty{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        i{
          color: #ccc;          
        }
        p{
          color: #ccc;     
          font-weight: bold;
          letter-spacing: 0.02em;     
        }
      }

      &.gadget{
        overflow: hidden;
        padding: 0 1em 1em 1em;
      }
      .link-wrapper{
        width: 100%;
        display: flex;
        justify-content: flex-end;
        >.link{
          font-size: 12px;
          display: flex;
          align-items: flex-end;
          text-transform: capitalize;
          cursor: pointer;
          >.v-icon{
            margin-left: .2em;
          }
          &:hover{
            color: blue;
            text-decoration: underline;
            >.v-icon{
              color: blue;
              text-decoration: none;
            }
          }
        }
      }
    }
  }
}

.btn-envio{
  width: 100%;
  height: 3em !important;
  text-transform: uppercase;
  letter-spacing: 0.02em;
  padding: 1em;
  border-radius: 10px;
  font-weight: bold;

  &.whitecolor{
    color: whitesmoke !important;
  }
  &.mb{
    margin-bottom: 1em;
  }
}

.modal-stakeholder-wrapper{
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: grid;
  place-items: center;
  background-color: rgb(240, 235, 248);

  .modal-stakeholder{

  }
}

//css indesejado
.col-md-6, .col-sm-4, .col-12{
  padding: 0 12px !important;
}

</style>




