import api from './api'

export default {
  criar(body){
    const config = {
      headers: { 'x-access-token': `${localStorage.getItem('tktaskcheck')}`}
    };
    return api().post('/usuario/upinsert', body, config)
  },
  atualizar(body){
    const config = {
      headers: { 'x-access-token': `${localStorage.getItem('tktaskcheck')}`}
    };
    return api().post('/usuario/upinsert', body, config)
  },
  buscar(body){
    const config = {
      headers: { 'x-access-token': `${localStorage.getItem('tktaskcheck')}` }
    };
    return api().post('/usuario/select', body, config)
  }
}