import api from './api'

export default {
  criar(body){
    const config = {
      headers: { 'x-access-token': `${localStorage.getItem('tktaskcheck')}`}
    }
    return api().post('/stakeholder/upinsert', body, config)
  },
  removerStakeHolder(body){
    const config = {
      headers: { 'x-access-token': `${localStorage.getItem('tktaskcheck')}`}
    }
    return api().post('/stakeholder/removerStakeHolder', body, config)
  },
  reativarStake(body){
    const config = {
      headers: { 'x-access-token': `${localStorage.getItem('tktaskcheck')}`}
    }
    return api().post('/stakeholder/reativarStake', body, config)
  },
  criarTipo(body){
    const config = {
      headers: { 'x-access-token': `${localStorage.getItem('tktaskcheck')}`}
    }
    return api().post('/stakeholder/tipo/upinsert', body, config)
  },
  buscar(body){
    const config = {
      headers: { 'x-access-token': `${localStorage.getItem('tktaskcheck')}`}
    }
    return api().post('/stakeholder/select', body, config)
  },
  buscarTipo(body){
    const config = {
      headers: { 'x-access-token': `${localStorage.getItem('tktaskcheck')}`}
    }
    return api().post('/stakeholder/tipo/select', body, config)
  },
  buscarTipoCliente(body){
    const config = {
      headers: { 'x-access-token': `${localStorage.getItem('tktaskcheck')}`}
    }
    return api().post('/stakeholder/buscarStakeHolder/cliente', body, config)
  },
  buscarCliente(body){
    const config = {
      headers: { 'x-access-token': `${localStorage.getItem('tktaskcheck')}`}
    }
    return api().post('/adesao/dadosCliente', body, config)
  },
  atualizarTiposStackholders(body){
    const config = {
      headers: { 'x-access-token': `${localStorage.getItem('tktaskcheck')}`}
    }
    return api().post('/stakeholder/tipo/update', body, config)
  },
  cadastrarTiposStackholders(body){
    const config = {
      headers: { 'x-access-token': `${localStorage.getItem('tktaskcheck')}`}
    }
    return api().post('/stakeholder/tipo/cadastrar', body, config)
  }
}