<template>
    <Implantacao />
</template>

<script>
import Implantacao from '@/components/Implantacao/AtualizarImplantacao'
export default {
  components: {Implantacao},
  data(){
    return{}
  }
}
</script>

<style>

</style>