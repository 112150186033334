import api from './api'

export default {
  criar(body){
    const config = {
      headers: { 'x-access-token': `${localStorage.getItem('tktaskcheck')}`}
    }
    return api().post('/fluxo/etapa/upinsert', body, config)
  },
  buscar(body){
    const config = {
      headers: { 'x-access-token': `${localStorage.getItem('tktaskcheck')}`}
    }
    return api().post('/fluxo/etapa/select', body, config)
  },
  vincular(body){
    const config = {
      headers: { 'x-access-token': `${localStorage.getItem('tktaskcheck')}`}
    }
    return api().post('/fluxo/etapa/vincularEtapaFluxo', body, config)
  },
  buscarPorFluxo(id){
    const config = {
      headers: { 'x-access-token': `${localStorage.getItem('tktaskcheck')}`}
    }
    return api().post('/fluxo/etapafluxo/select', {idfluxo: id}, config)
  },
  buscarPorFluxoImplantacao(body){
    const config = {
      headers: { 'x-access-token': `${localStorage.getItem('tktaskcheck')}`}
    }
    return api().post('/fluxo/etapafluxoImplantacao/select', body, config)
  }
}