import api from './api'

export default {
  criarPergunta(body){
    const config = {
      headers: { 'x-access-token': `${localStorage.getItem('tktaskcheck')}`}
    };
    return api().post('/gadget/pergunta/upinsert', body, config)
  },
  criarGadget(body){
    const config = {
      headers: { 'x-access-token': `${localStorage.getItem('tktaskcheck')}`}
    };
    return api().post('/gadget/upinsert', body, config)
  },
  buscar(body){
    const config = {
      headers: { 'x-access-token': `${localStorage.getItem('tktaskcheck')}`}
    };
    return api().post('/gadget/select', body, config)
  },
  tipo(body){
    const config = {
      headers: { 'x-access-token': `${localStorage.getItem('tktaskcheck')}`}
    };
    return api().post('/gadget/tipo/select', body, config)
  },
  buscarPerguntas(idgadget){
    const config = {
      headers: { 'x-access-token': `${localStorage.getItem('tktaskcheck')}`}
    };
    return api().post('/gadget/pergunta/select', {idgadget: idgadget}, config)
  },
  deletarPerguntas(body){
    const config = {
      headers: { 'x-access-token': `${localStorage.getItem('tktaskcheck')}`}
    };
    return api().post('/gadget/pergunta/delete', body, config)
  },
  criarTransicao(body){
    const config = {
      headers: { 'x-access-token': `${localStorage.getItem('tktaskcheck')}`}
    };
    return api().post('/gadget/transicao/upinsert', body, config)
  },
  buscarTransicao(body){
    const config = {
      headers: { 'x-access-token': `${localStorage.getItem('tktaskcheck')}`}
    };
    return api().post('/gadget/transicao/select', body, config)
  }
}